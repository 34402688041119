.tab-container {
    text-align: center;
    border-bottom: 1px solid $color-light-gray;
    position: relative;
    line-height: 11px;

    &.align-left {
        text-align: left;
    }

    ul {
        display: inline-block;
        list-style: none;
        margin: 0 auto;

        li {
            float: left;

            a {
                color: $color-medium-dark-gray;
                font-size: 1.25rem;
                padding: 1rem;
                display: block;
                border-bottom: 4px solid transparent;

                &.active {
                    border-bottom: 4px solid $color-brand;
                }

                .icon {
                    margin: 0 1rem 0 0;

                    &.icon-tips {
                        color: $color-brand-yellow;
                    }

                    &.icon-health {
                        color: $color-brand-pink;
                    }

                    &.icon-lifestyle {
                        color: $color-brand-purple;
                    }

                    &.icon-performance {
                        color: $color-brand-orange;
                    }
                }

                &:hover {
                    border-bottom: 4px solid $color-brand;
                }
            }
        }
    }
}