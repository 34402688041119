.coach-intro {
    background: $color-brand;
    color: $white;
    position: relative;
    transform-style: preserve3d;
    perspective: 100px;
    padding: 8.5rem $global-baseline + px 0 4rem;
    //overflow: hidden;
    z-index:50;

    .max-width-container {
        margin: 0 auto 6rem auto;
    }

    .intro-content {
        padding-bottom: 2rem;

        h2 {
            font-size: 1.25rem;
            margin: 0;
        }

        h1 {
            margin: 0;
        }

        p {
            margin-bottom: 0;
            padding-bottom: 1rem;
        }
        .photo-container {
            img {
                min-width: 22rem;
                margin-left: -176px;
                left: 50%;
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .grid {
                display: grid;
                grid-template-columns: 45% 45%;
                grid-gap: 5%;

            }
            .photo-container {
                img {
                    min-width: 22rem;
                    margin-left: -176px;
                    left: 50%;
                }
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
            .grid {
                display: grid;
                grid-template-columns: 58% 37%;
                grid-gap: 5%;

            }
            .photo-container {
                img {
                    min-width: 22rem;
                    right: 0;
                    margin-left: 0;
                    left: auto;
                }
            }
        }
    }

    .angle-bkgd {
        position: absolute;
        background: $color-brand;
        height: 100%;
        transform: rotate(-4deg);
        left: -10%;
        right: -10%;
        bottom: -50px;
    }
    .flair-svg {
        position: absolute;
        top: 0.5rem;
        left: -2rem;
        z-index: 30;
        height: 200%;
    }

    .photo-container {
        min-height: 16rem;
        position: relative;

        img {
            min-width: 21rem;
            position: absolute;
            bottom: -95px;
            box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
            left: auto;
            right: 0;
            margin-left: 0;
        }
    }
  }

.coach-content-container {
    overflow: hidden;
    position:relative;
    z-index: 60;
    padding: 0 $global-baseline + px $global-baseline + px 4rem;
    margin-top: -31px;

    .coach-specifics {
        display: none;
    }

    @include mediaQuery(map-get($grid-breakpoints, medium)) {
        margin-top: -19px;
    }

    @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
        margin-top: -12px;

        .coach-specifics {
            display: block;
            float: right;
            margin: 0 0 $global-baseline + px $global-baseline + px;
            width: 22rem;

            h5 {
                font-size: 1.25rem;
                margin: 0 0 0.5rem 0;
            }

            p {
                margin-top: 0;
                font-size: 1rem;
            }

            .key-info {
                margin: 0;
                li {
                    a {
                        color: $color-brand-orange !important;
                    }
                }
            }
        }
    }

    @include mediaQuery(map-get($grid-breakpoints, large)) {
        margin-top: 0px;
    }

    .flair-svg-blue {
        position: absolute;
        left: -32px;
        top: 0;
    }

    .coach-content {
        padding-top: 1.5rem;
    }
}
