.footer-main {
    background: $color-brand;
    clear: both;
    color: $white;
    display: inline-block;
    padding: $global-baseline + px;
    position: relative;
    width: 100%;
    z-index:500;

    .footer-logo {
        display: none;
    }

    .join-us-block {
        display: block;
        margin: 6rem 0;
        text-align: center;

        .container {
            margin: 0 auto;
            display: inline-block;

            .get-started {
                @include setType(14, 0);
                display: block;
                text-align: left;
            }

            a.join-us {
                @include setType(42, 0);
                color: $white;
                display: block;
                margin: 0.5rem 0 0 0;
                font-family: $font-heading;
                font-weight: 600;
                text-transform: uppercase;

            }
        }

    }

    .footer-menu {
        text-align: center;
        margin: 2rem 0;

        ul {
            @extend %scrub-list-style;
            margin: 0;

            li {
                display: inline;

                a {
                    @include setType(12, 0);
                    color: $white;

                    &:hover {
                        text-decoration: underline;
                    }

                }

                &:after {
                    content: '/';
                    padding: 0 0.5rem;
                }

                &:last-child {
                    &:after {
                        content: '';
                    }
                }
            }
        }
    }

    .instagram {
        display: block;
        text-align: center;

        .icon-instagram {
            color: $white;
            font-size: 1.5rem;
        }
    }

    .copyright {
        @include setType(12, 0);
        display: block;
        margin: 2rem 0;
        text-align: center;
        width: 100%;
        line-height: 18px;

    }

    @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
        .join-us-block {
            .container {
                a.join-us {
                    @include setType(60, 0);

                }
            }
        }

        .footer-menu {
            text-align: left;
            margin: 1rem 0;
            ul {
                li {
                    display: inline;
                    a {
                        @include setType(14, 0);
                    }

                    &:after {
                        content: ' ';
                    }
                }
            }
        }

        .instagram {
            float: left;
            display: inline-block;
        }

        .copyright {
            float: left;
            display: inline-block;
            width: auto;
            text-align: left;
            margin: 0.125rem 0 0 1rem;
        }

        .footer-logo {
            display: block;
            position: absolute;
            right: 2rem;
            bottom: -2rem;
        }
    }
}
