.tiles-container {
   display: none;
}

.intro-carousel {
    &.tablet {
        display: none;
    }
}

.home {
    .main-container {
        section:nth-child(3) {
            margin-top: -5rem;
        }
    }
}

.education {
    max-width: 56rem;
    margin: 0 auto;
    .wrapper {
        padding: 0 24px;

        .grid {
            @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .image {
                    grid-column: 2;
                    grid-row: 1 / 1;
                    max-width: 25rem;
                }
                .content {
                    grid-column: 1;
                    grid-row: 1 / 1;
                }
            }
        }
    }
}

.hp-intro {
    color: $white;
    position: relative;
    transform-style: preserve3d;
    perspective: 100px;
    padding: 10rem $global-baseline + px 0 4rem;
    overflow: hidden;
    z-index:50;

    &.bkgd-gradient {
        &::before {
            content: '';
            background: linear-gradient(125deg, rgba(54,102,255,1) 0%, rgba(54,102,255,0.3) 60%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
        }

        @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
            &::before {
                background: linear-gradient(90deg, rgba(54,102,255,1) 0%, rgba(54,102,255,1) 40%, rgba(54,102,255,0.75) 65%, rgba(54,102,255,0.2) 100%);
            }
        }
    }

    .max-width-container {
        margin: 0 auto 5.5rem auto;
        min-height: 27rem;
        z-index: 800;
    }

    .hp-intro-content {
        text-align: center;
        padding-bottom: 2rem;

        h1 {
            font-size: 1.25rem;
        }
    }

    &:after {
        background: $white;
        content: "";
        height: 20rem;
        left: 0px;
        position: absolute;
        right: -4px;
        transform-origin: 0 0;
        transform: rotate(2deg);
        margin-top: -6rem;
        z-index: 600;
        background: repeating-linear-gradient(
        94deg,
        #fff 50px, #fff 50px, #F5F8FA 50px, #F5F8FA 51px, #fff 52px, #fff 449px);
    }

    .hp-intro-logo  {
        margin-top: 4rem;
        text-align: center;

        img {
            max-width: 130px;
        }
    }
    .flair-svg {
        position: absolute;
        top: 3.75rem;
        left: -2rem;
        z-index: 30;
        height: 200%;
    }

  }

  .how-we-help-you {
      overflow: hidden;
      position:relative;
      z-index: 60;
      padding: 0 $global-baseline + px 7rem 4rem;
      margin-top: -92px;
        .flair-svg-blue {
            position: absolute;
            left: -32px;
            top: 0;
        }
  }

  .client {
        h4 {
            margin-top: 0;
        }

        &.layout-40-60 {
            display: grid;
            grid-template-columns: 38% 57%;
            grid-gap: 5%;
        }
    }

  @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
    .hp-intro {

        .hp-intro-logo img {
            display: none;
        }

        .hp-intro-content {
            text-align: left;
            padding-bottom: 2rem;
            max-width: 65%;

            h1 {
                font-size: 2.5rem;
            }
        }

        .flair-svg {
            top: 0.75rem;
            height: 200%;
        }
    }
  }

.client {
    padding-bottom: $global-baseline + px;

    img {
        width: 100%;
        height: auto;
        margin: 0 auto 1rem auto;
        box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
        //max-width: 25rem;
    }

    h4 {
        text-transform: uppercase;
        color: $color-medium-gray;
        font-size: 0.9rem;
        font-weight: 600;
        margin: 1rem 0 0;
    }

    h5 {
        font-family: $font-heading;
        text-transform: uppercase;
        color: $black;
        font-size: 1.25rem;
        font-weight: 600;
        margin: 0 0 1rem 0;
    }

    p {
        font-size: 1.125rem;
    }

    em {
        color: $color-dark-gray;
    }
}

.our-coaches {
    .center {
        text-align: center;
    }
    .coach-carousel {
        margin: 0 2rem;
        display: block;

        .slides > li {
            text-align: center;
            a {

                h2 {
                    font-family: $font-heading;
                    color: $black;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 1.25rem;
                    margin-bottom: 0;

                }
            }

            h2 {
                color: $color-brand-orange;
                margin-bottom: 0;
            }

            img {
                border-radius: 2px;
                box-shadow: 0 7px 12px 0 rgba(211,213,215,0.77);
                width: 100%;
                height: auto;
                max-width: 28rem;
                margin: 0 auto;
            }
        }
    }

    .coach {
        img {
            width: 100%;
            height: auto;
            margin: 0 auto 1rem auto;
            box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
            //max-width: 25rem;
        }

        h4 {
            text-transform: uppercase;
            color: $color-medium-gray;
            font-size: 0.9rem;
            font-weight: 600;
            margin: 1rem 0 0;
        }

        h5 {
            font-family: $font-heading;
            text-transform: uppercase;
            color: $black;
            font-size: 1.25rem;
            font-weight: 600;
            margin: 0 0 1rem 0;
        }

        p {
            font-size: 1.125rem;
        }

        em {
            color: $color-dark-gray;
        }
    }

    .medium {
        display: none;
    }
}

.more-client-stories, .more-coaches {
    display: block;
    text-align: center;
    padding: $global-baseline + px 0;
}

 @include mediaQuery(map-get($grid-breakpoints, medium)) {

    .our-coaches {
        .coach-carousel {
            margin: 0 4rem;

            .slides > li {
                text-align: left;
            }

            h2 {
                font-size: 1.25rem;
            }
        }
    }

    .grid {
        display: inline-grid;
        grid-template-columns: auto auto;
        grid-gap: 4rem;
    }
}

@include mediaQuery(map-get($grid-breakpoints, med-large)) {
    .hp-intro .hp-intro-content, .how-we-help-you .how-we-help-you-content {
        max-width: 36rem;
    }

    .tablet-carousel {
        height: 30rem;
        width: 30rem;
        position: absolute;
        top: 10rem;
        right: 2rem;
        z-index: 200;
    }
    .intro-carousel {
        &.tablet {
            display: block;
            text-align: center;

            h2 {
                font-size: 1.5rem;
                color: $white;
            }

            &.flexslider .slides img {
                max-width: 28rem;
            }

        }
    }
}

@include mediaQuery(map-get($grid-breakpoints, large)) {
    .tablet-carousel {
        display: none;
    }
    .tiles-container {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 14rem;
        //width: 40%;
        max-width: 39rem;
        z-index: 100;

        .hp-tiles {
            display: grid;
            grid-template-rows: 50% 50%;
            grid-auto-flow: column;
            grid-gap: 1rem 4rem;

            .tile {
                align-self: stretch;

                &.perform-work, &.perform-home {
                    margin-top: -6rem;
                }

                &.dark {
                    h2 {
                        color: $black;

                        &:before {
                            background: $color-brand;
                        }
                    }
                }

                h2 {
                    font-family: $font-heading;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 1.45rem;
                    color: $white;
                    position: relative;
                    margin-left: 1rem;

                    &:before {
                        content: '';
                        top: 50%;
                        margin-top: -3.5px;
                        left: -1rem;
                        height: 7px;
                        width: 7px;
                        background: $white;
                        position: absolute;
                    }
                }

                img {
                    border-radius: 2px;
                    box-shadow: 0 7px 12px 0 rgba(0,0,0,0.2);
                    width: 100%;
                    height: auto;
                    width: 28rem;
                    margin: 0 auto;
                }
            }
        }
    }
    .hp-intro {
        .hp-intro-content {
            padding-bottom: 2rem;
            max-width: 36rem;
        }
    }

    .how-we-help-you {
        margin-top: -92px;
        padding: 0 $global-baseline + px 7rem 4rem;
    }
}

@include mediaQuery(map-get($grid-breakpoints, x-large)) {
    .how-we-help-you {
        margin-top: -66px;
    }
}

@keyframes draw-down {
    0% {
        opacity: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 20px;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}