.how-it-works {

    .sticky-footer {
        background: $color-light-gray;
        margin: 0 auto;
        width: 100%;
        position: sticky;
        bottom: 0;
        text-align: center;
        padding: 1rem 0;
        z-index: 500;

        h3 {
            font-size: 1.5rem;
        }

        a {
            margin-bottom: 0.75rem;
        }
    }
    .how-it-works-intro {
        position: relative;
        transform-style: preserve3d;
        perspective: 100px;
        padding: 4rem $global-baseline + px 0 $global-baseline + px;
        overflow: hidden;
        text-align: center;

        .max-width-container {
            padding-bottom: 4rem;
            max-width: 76rem;
        }

        .angle-bkgd {
            position: absolute;
            background: $color-light-gray;
            height: 100%;
            transform: rotate(-4deg);
            left: -10%;
            right: -10%;
            top: -75px;
        }
    }

    .line {
        position: absolute;
        left: 10px;
        height: 100%;
    }

    .steps-wrapper {
        position: relative;
        max-width: 40rem;
        margin: 0 auto 21rem;

        .blue-line {
            opacity: 0;
            position: absolute;
            left: 54px;
            border-left: 2px solid $color-brand;
            top: 0;
            height: 91%;
            z-index: 50;
        }

        .white-line {
            position: fixed;
            height: 100%;
            border-left: 2px solid $white;
            z-index: 100;
        }
    }

    .steps {
        list-style: none;
        margin: 0 1rem 0 6rem;

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            margin: 0 1rem 0 8rem;
        }

        h3 {
            margin-bottom: 0;
        }

        p {
            margin-top: 0;
        }

        li {
            margin-bottom: 4rem;
        }

        .step {
            position: relative;

            &.active {
                .step-icon {
                    .color-icon {
                        display: block;
                    }

                    .gray-icon {
                        display: none;
                    }
                }
            }

            .step-icon {
                position: absolute;
                top: 0;
                left: -4rem;
                height: 3rem;
                width: 3rem;
                z-index: 200;

                &.white {
                    background: $white;
                }

                @include mediaQuery(map-get($grid-breakpoints, medium)) {
                    left: -6.5rem;
                    height: 5rem;
                    width: 5rem;
                }

                .color-icon {
                    display: none;
                }

                .gray-icon {
                    display: block;
                }
            }
        }

        .step-6 {
            margin-bottom: 8rem;
        }
    }


}