.overlay {
    background: $black;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;

    &.active {
        display: block;
        opacity: 1;
    }
}

.overlay-active {
    overflow: hidden;
}

.close {
    color: $white;
    display: none;
    font-size: 1.5rem;
    opacity: .25;
    top: 30px;
    right: 50px;

    &:hover {
        cursor: pointer;
    }
}
