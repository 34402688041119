.search-form {
    position: relative;

    .search-btn { 
        color: #476C61;
        position: absolute;
        right: 5px;
        text-indent: -1100px;
        top: 8px; 
        border: 0;
        background: none;

        &:before {
            background: transparent;
            content: "\e905";
            display: block;
            font-family: 'codescience';
            height: 20px;
            left: auto;
            right: 20px;
            text-indent: 0;
            top: 10px;
            transform: none;
            width: 20px;
        }

        &:after {
            display: none;
        }

        &:hover {
            animation: none;
            animation-fill-mode: none;
            color: #476C61;
            outline: 0;
            transform: none;

            &:before {
                border-radius: 0;
                transform: none;
            }

            &:after {
                animation: none;
                animation-fill-mode: none;
                border-radius: 0;
            }
        }
    }
}