.block {
    padding: 0 0 2rem 0;

    .block-header {
        display: grid;
        grid-template-columns: 5% 20% 30% 20% 5%; // -20%
        grid-column-gap: 5%; // 20%
        justify-items: center;

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            grid-template-columns: 5% 20% 30% 20% 5%; // -20%
        }

        .block-header-text {
            text-align: center;
        }

        h3 {
            font-family: $font-heading;
            text-transform: uppercase;
            font-weight: 600;
            color: $black;
            font-size: 1.5rem;
            line-height: 1.75rem;

            @include mediaQuery(map-get($grid-breakpoints, medium)) {
                font-size: 2rem;
                line-height: 2.25rem;
            }
        }

        .fancy-bar {
            position: relative;
            height: 100%;
            width: 100%;

            &.right {
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 100%;
                    border-top: 1px solid $color-brand-pink;
                }

                &:after {
                    content: '';
                    height: 8px;
                    width: 8px;
                    background: $color-brand-pink;
                    position:absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -4px;
                }

                &.orange {
                    &:before {
                        border-top: 1px solid $color-brand-orange;
                    }

                    &:after {
                        background: $color-brand-orange;
                    }
                }
            }

            &.left {
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    border-top: 1px solid $color-brand-pink;
                }

                &:after {
                    content: '';
                    height: 8px;
                    width: 8px;
                    background: $color-brand-pink;
                    position:absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -4px;
                }

                &.orange {
                    &:before {
                        border-top: 1px solid $color-brand-orange;
                    }

                    &:after {
                        background: $color-brand-orange;
                    }
                }
            }
        }
    }

    &.blog-post-callout {
        max-width: 60rem;
        margin: 0 auto;
        .wrapper {
            padding: 2rem;
        }
        .grid {

            &.two-col {
                grid-template-columns: 35% 55%;
                grid-gap: 5%;
            }

            .col {
                .tag {
                    padding-top: 1rem;
                    display: block;
                    .icon {
                        padding-right: 0.5rem;
                    }
                }

                a h5 {
                    color: $black;
                    font-size: 1.5rem;
                }

                p {
                    font-size: 1rem;
                }
                img {
                    margin: 1rem;
                    width: 90%;
                    box-shadow: 0 7px 12px 0 rgba(211,213,215,0.77)
                }
            }
        }
    }

    &.client-testimonials {
        &.hear-more {
            h3.header {
                text-align: center;
                padding: 0;
                font-size: 1.75rem;

                &:before, &:after {
                    display: none;
                }
            }
            .center {
                text-align: center;
            }
            @include mediaQuery(map-get($grid-breakpoints, medium)) {
                .grid {
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-gap: 2rem 4rem;

                    .grid {
                        grid-gap: 2rem;
                    }
                }
            }
        }

        .grid {
            display: grid;
            grid-template-columns: auto ;
            grid-gap: 0;
        }

        h3.header {
            font-size: 1.5rem;
            margin: 0 0 0 -24px;
            padding: 0 0 0 40px;
            position: relative;

            &:before {
                content: '';
                border-top: 1px solid $color-brand-pink;
                width: 20px;
                position: absolute;
                top: 50%;
                left: 0;
            }

            &:after {
                content: '';
                height: 8px;
                width: 8px;
                background: $color-brand-pink;
                position: absolute;
                left: 20px;
                top: 50%;
                margin-top: -3px;
            }
        }

        .client {
            padding-bottom: $global-baseline + px;

            img {
                width: 100%;
                height: auto;
                margin: 0 auto 1rem auto;
                box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
                //max-width: 25rem;
            }

            h4 {
                text-transform: uppercase;
                color: $color-medium-gray;
                font-size: 0.9rem;
                font-weight: 600;
                margin: 1rem 0 0;
            }

            h5 {
                font-family: $font-heading;
                text-transform: uppercase;
                color: $black;
                font-size: 1.25rem;
                font-weight: 600;
                margin: 0 0 1rem 0;
            }

            p {
                font-size: 1.125rem;
            }

            em {
                color: $color-dark-gray;
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .grid {
                display: grid;
                grid-template-columns: auto auto auto;
                grid-gap: 4rem;
            }
        }
    }

    &.blog-callout {

        &.coach {
            .blog-img {
                display: none;
            }

            a > h5 {
                color: $black;
                font-size: 1.5rem;
                margin: 0 0 1rem 0;
                line-height: 1.75rem;
            }

            p {
                margin-top: 0;
                display: block;
            }
        }

        .tag {
            font-size: 0.95rem;

            .icon {
                margin-right: 0.25rem;
            }
        }
        h3.header {
            font-size: 1.5rem;
            margin: 0 0 $global-baseline + px + ' ' + -$global-baseline + px;
            padding: 0 0 0 40px;
            position: relative;

            &:before {
                content: '';
                border-top: 1px solid $color-brand;
                width: 20px;
                position: absolute;
                top: 50%;
                left: 0;
            }

            &:after {
                content: '';
                height: 8px;
                width: 8px;
                background: $color-brand;
                position: absolute;
                left: 20px;
                top: 50%;
                margin-top: -3px;
            }

            .tag {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                background: $color-brand;
                color: $white;
                font-size: 1rem;
                box-shadow: 0 2px 10px 0 rgba(211, 213, 215, 100);
                text-transform: none;
                font-style: normal;
                font-family: $font-main;
                padding: 0.5rem 1rem;
                border-radius: 2px;

                 a {
                     color: $white;
                 }

            }
        }

        a > h5 {
            color: $black;
            font-size: 1.5rem;
            margin: 0 0 2rem 0;
            line-height: 1.75rem;
        }

        p {
            display: none;
        }


        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            &.coach {
                .blog-img {
                    display: inline-block;
                }
            }
            .grid {
                display: grid;
                grid-template-columns: auto auto auto;
                grid-gap: 5%;
            }

            h3.header {
                .tag {
                    display: block;
                }
            }

            a > h5 {
                color: $black;
                font-size: 1.5rem;
                margin: 0;
            }

            p {
                margin-top: 0.5rem;
                display: block;
            }
        }
    }

    &.meet-our-coaches {
        .wrapper {
            max-width: 66rem;
            margin: 0 auto;
        }
        h3 {
            margin-top: 0;
            text-align: center;
        }
        .grid {
            display: grid;
            grid-template-columns: auto;
            grid-gap: 2rem;

            .col {
                img {
                    width: 100%;
                    display: block;
                }
                .btn-accent {
                    margin-top: 1rem;
                    display: inline-block;
                    clear: both;
                    text-transform: uppercase;
                }
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .grid {
                grid-template-columns: auto auto auto;
                grid-gap: 3rem;
            }
        }
    }

    &.our-coaches {
        max-width: 70%;
        margin: 0 auto;
        p {
            margin: 0 auto 24px;
        }

        &.coach-landing {
            .block-header {
                &.desktop {
                    display: none;
                }

                &.mobile {
                    display: grid;
                }

                @include mediaQuery(map-get($grid-breakpoints, medium)) {
                    &.mobile {
                        display: none;
                    }
                    &.desktop {
                        display: block;

                        h3 {
                            span {
                                padding: 0 0 0.25rem 0;
                                border-bottom: 1px solid $color-brand-orange;
                            }
                        }
                    }
                }
            }
            .coach img {
                margin-bottom: 2rem;
            }
        }
    }

    &.content {
        padding: 2rem;

        &.cta-style-one {
            h2 {
                margin: 0;
                font-size: 1.625rem;
                line-height: 1.95rem;
                font-style: italic;
            }

            .cta-angle {
                position: relative;
                padding: 3rem 2rem;
                background: $color-light-gray;
                &::before {
                    content: '';
                    background: $white;
                    transform: rotate(3deg);
                    height: 2rem;
                    position: absolute;
                    top: -1rem;
                    left: -1px;
                    width: 101%;
                }

                &::after {
                    content: '';
                    background: $white;
                    transform: rotate(3deg);
                    height: 2rem;
                    position: absolute;
                    bottom: -1rem;
                    left: -1px;
                    width: 101%;
                }
            }

            .btn-accent {
                position: relative;
                z-index: 200;
                margin-top: -2rem;
            }

        }

        &.center {
            text-align: center;
            margin: 0 auto;
        }

        blockquote {
            font-size: 1.5rem;
        }

        &.layouts {
            .layout-40-60, .layout-60-40, .layout-50-50 {
                display: grid;
                grid-template-columns: auto;
                grid-gap: auto;
            }

            .col {
                img {
                    width: 100%;
                    box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
                }
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            padding: 2rem 0;
            margin: 0 auto;
            max-width: 40rem;

            &.layouts {
                max-width: 60rem;

                .layout-40-60 {
                    display: grid;
                    grid-template-columns: 38% 57%;
                    grid-gap: 5%;
                }

                .layout-60-40 {
                    display: grid;
                    grid-template-columns: 57% 38%;
                    grid-gap: 5%;
                }

                .layout-50-50 {
                    width: 100%;
                    display: grid;
                    grid-template-columns:47.5% 47.5%;
                    grid-gap: 5%;
                }
            }
        }
    }
}

.cta-style-two {
    background: $color-brand;
    padding: 3rem 0;
    position: relative;

    h2 {
        position: relative;
        margin: 0 auto 3rem;
        color: $white;
        max-width: 40rem;
        line-height: 1.95rem;

        &::after {
            content: '';
            width: 14rem;
            height: 1px;
            background: $white;
            position: absolute;
            bottom: -1rem;
            left: 50%;
            margin-left: -7rem;
        }
    }

    p {
        margin: 24px auto;
        color: $white;
        max-width: 30rem;
    }

    &::before {
        content: '';
        background: $white;
        transform: rotate(2deg);
        width: 101%;
        position: absolute;
        top: -2.5rem;
        height: 4.5rem;
        left: -1px;
    }

    &::after {
        content: '';
        background: $white;
        transform: rotate(2deg);
        width: 101%;
        position: absolute;
        bottom: -2.5rem;
        height: 4.5rem;
        left: -1px;
    }

}

.quote {
    blockquote {
        position: relative;
        color: $white;
        font-style: normal;

        &::before {
            content: '"';
            position: absolute;
            top: 0;
            left: -2rem;
            font-size: 4rem;
            font-family: $font-main;
            font-style: italic;
            font-weight: 600;
            color: $white;
            opacity: 0.5;
        }

        &::after {
            content: '"';
            position: absolute;
            bottom: 0;
            right: -1.5rem;
            font-size: 4rem;
            font-family: $font-main;
            font-style: italic;
            font-weight: 600;
            color: $white;
            opacity: 0.5;
        }
    }

    cite {
        font-family: $font-heading;
        font-weight: 600;
        color: $white;
        text-transform: uppercase;
    }
}

.newsletter-signup {
    .newsletter-signup-form {
        grid-template-columns: 50% 50%;
        grid-column-gap: 5%;
        justify-items: center;

        input::-webkit-input-placeholder {
            color: $color-medium-gray;
            font-style: normal;
        }

        .col {
            margin-bottom: 1.5rem;
        }

        .infusion-option {
            display: block;
            margin-bottom: 1rem;
        }

        .subscribe-checkbox {
            display: inline-block;
            color: $white;
            margin-left: 0.5rem;
        }

        .btn-accent {
            border: none;
        }
    }

    .infusion-submit {
        display: inline-block;
    }
}

.cta-style-three {
    background: $color-brand;
    padding: 3rem 0;
    position: relative;
    margin-bottom: 3rem;

    .bkgd-gradient {
        background: linear-gradient(125deg, rgba(54,102,255,1) 0%, rgba(54,102,255,0.3) 60%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
            background: linear-gradient(90deg, rgba(54,102,255,1) 0%, rgba(54,102,255,1) 40%, rgba(54,102,255,0.75) 65%, rgba(54,102,255,0.2) 100%);
        }
    }

    &.desktop {
        display: none;
    }

    .content {
        position: relative;
        z-index: 20;
    }

    h2 {
        position: relative;
        margin: 0 auto 3rem;
        color: $white;
        max-width: 40rem;
        line-height: 1.95rem;

        &::after {
            content: '';
            width: 14rem;
            height: 1px;
            background: $white;
            position: absolute;
            bottom: -1rem;
            left: 50%;
            margin-left: -7rem;
        }
    }

    p {
        margin: 24px auto;
        color: $white;
        max-width: 30rem;
    }

    &::before {
        content: '';
        background: $white;
        transform: rotate(2deg);
        width: 101%;
        position: absolute;
        top: -2.5rem;
        height: 4.5rem;
        left: -1px;
        z-index: 10;
    }

    &::after {
        content: '';
        background: $white;
        transform: rotate(2deg);
        width: 101%;
        position: absolute;
        bottom: -2.5rem;
        height: 4.5rem;
        left: -1px;
        z-index: 10;
    }

    @include mediaQuery(map-get($grid-breakpoints, medium)) {
        &.desktop {
            display: block;
        }

        &.mobile {
            display: none;
        }
    }

}