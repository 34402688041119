.branded-link {
    appearance: none;
	color: $black;
    background-color: transparent;
    border: 0;
    border-radius: 2px;
    display: inline-block;
	font-family: $font-main;
    font-size: 1rem;
    font-weight: 600;
    overflow: hidden;
    position: relative;
	text-decoration: none;
    z-index: 1;

    &.blue {
        color: $color-brand;
    }

    &.pink {
        color: $color-brand-pink;
    }

    &.orange {
        color: $color-brand-orange;
    }


}

.btn-default {
    background: $white;
    color: $color-brand-pink;
    border-radius: 2px;
    font-family: $font-main;
    font-size: 1.125rem;
    font-weight: 500;
    padding: 6px 14px;

    &.purple {
        color: $color-brand-purple;

        &:hover {
            animation: btn-default-purple .2s ease-out .1s;
            animation-fill-mode: forwards;
            cursor: pointer;
        }
    }

    &.yellow {
        color: $color-brand-yellow;

        &:hover {
            animation: btn-default-yellow .2s ease-out .1s;
            animation-fill-mode: forwards;
            cursor: pointer;
        }
    }

    &.orange {
        color: $color-brand-orange;

        &:hover {
            animation: btn-default-orange .2s ease-out .1s;
            animation-fill-mode: forwards;
            cursor: pointer;
        }
    }

    &.min-pad {
        padding: 6px 14px;
    }

    &.no-shad {
        box-shadow: none;
    }

    &:hover {
        animation: btn-default .2s ease-out .1s;
        animation-fill-mode: forwards;
        cursor: pointer;
    }
}

.btn-accent {
    background: $color-brand-pink;
    color: $white;
    border-radius: 2px;
    display: inline-block;
    font-family: $font-main;
    font-size: 1.125rem;
    font-weight: 500;
    padding: 14px 30px;
    box-shadow: 0 7px 9px 0 rgba(31, 61, 153, 0.56);
    text-align: center;

    &.full-width {
        width: 100%;
    }

    &.min-pad {
        padding: 6px 14px;
    }

    &.min-shad {
        box-shadow: 0 2px 10px 0 rgba(211, 213, 215, 100);
    }

    &.no-shad {
        box-shadow: none;
    }

    &.purple {
        background: $color-brand-purple;

        &:hover {
            animation: btn-bg-accent-purple .2s ease-out .1s;
            animation-fill-mode: forwards;
        }
    }

    &.yellow {
        background: $color-brand-yellow;

        &:hover {
            animation: btn-bg-accent-yellow .2s ease-out .1s;
            animation-fill-mode: forwards;
        }
    }

    &.orange {
        background: $color-brand-orange;

        &:hover {
            animation: btn-bg-accent-orange .2s ease-out .1s;
            animation-fill-mode: forwards;
        }
    }


    &:hover {
        animation: btn-bg-accent .2s ease-out .1s;
        animation-fill-mode: forwards;
        cursor: pointer;
    }
}

.btn-light {
    &:after {
        background: $white;
    }

    &:hover {
        animation: btn-text .2s ease-out .1s;
        animation-fill-mode: forwards;
        outline: 0;

        &:before {
            border-radius: 2px;
            top: 0;
            transform: translate3d(0, 0, 0);
        }

        &:after {
            animation: btn-bg-accent .2s ease-out .1s;
            animation-fill-mode: forwards;
            border-radius: 2px;
        }
    }
}

@keyframes btn-text {
    0% {
        color: $color-brand;
    }

    100% {
        color: $white;
    }
}

@keyframes btn-default {
    0% {
        color: $color-brand-pink;
        background: $white;
    }

    100% {
        color: $white;
        background: $color-brand-pink;
    }
}

@keyframes btn-default-purple {
    0% {
        color: $color-brand-purple;
        background: $white;
    }

    100% {
        color: $white;
        background: $color-brand-purple;
    }
}

@keyframes btn-default-yellow {
    0% {
        color: $color-brand-yellow;
        background: $white;
    }

    100% {
        color: $white;
        background: $color-brand-yellow;
    }
}

@keyframes btn-default-orange {
    0% {
        color: $color-brand-orange;
        background: $white;
    }

    100% {
        color: $white;
        background: $color-brand-orange;
    }
}

@keyframes btn-bg {
    0% {
        background: $white;
    }

    100% {
        background: $color-brand;
    }
}

@keyframes btn-bg-accent {
    0% {
        background: $color-brand-pink;
        color: $white;
    }

    100% {
        background: $white;
        color: $color-brand-pink;
    }
}

@keyframes btn-bg-accent-purple {
    0% {
        background: $color-brand-purple;
        color: $white;
    }

    100% {
        background: $white;
        color: $color-brand-purple;
    }
}

@keyframes btn-bg-accent-yellow {
    0% {
        background: $color-brand-yellow;
        color: $white;
    }

    100% {
        background: $white;
        color: $color-brand-yellow;
    }
}

@keyframes btn-bg-accent-orange {
    0% {
        background: $color-brand-orange;
        color: $white;
    }

    100% {
        background: $white;
        color: $color-brand-orange;
    }
}

.entry-tag {
    @include setType(12, 0);

    background-color: $color-brand-pink;
    border-radius: 4px;
    display: inline-block;
    font-family: $font-heading;
    font-weight: 500;
    margin: $global-baseline/2 + px 0;
    padding: 0 $global-baseline/2 + px;

    &:hover,
    &:focus {
        background-color: darken($color-brand-pink, 10%);
    }

    &.medium {
        padding: 4px $global-baseline + px;

        &:hover,
        &:focus {
            background-color: darken($color-brand-pink, 10%);
        }
    }

    &.large {
        @include setType(16, 0);

        padding: 8px $global-baseline + px;

        &:hover,
        &:focus {
            background-color: darken($color-brand-pink, 10%);
        }
    }
}
