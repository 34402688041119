%scrub-list-style {
    list-style: none;
}

%clearfix {
    &:after {
        clear: both;
        content: " ";
        display: table;
    }
}

%hover {
    &:hover {
        cursor: pointer;
    }
}

%boxshadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
}

%boxshadow-dark {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}
