.pagination {
    @extend %clearfix;

    .prev {
        float: left;
    }

    .next {
        float: right;
    }
}
