.sub-nav {
    width: 100%;
    display: block;
    background: $color-light-gray;

    &.active {
        @extend %boxshadow;

        position: fixed;
        top: $global-baseline * 2 + px;
        z-index: 100;

        &+ .content-main {
            padding-top: 98px;
        }
    }

    .rss {
        span {
            font-size: 2rem;
            margin: 0 10px 0 0;
        }
    }
}

.sub-nav-items {
    @include setType(16, 0);
    @extend %scrub-list-style;

    text-align: center;

    li {
        display: inline-block;
    }

    a {
        @include setType(16, 0);

        border-bottom: 2px solid transparent;
        color: $black;
        display: block;
        font-style: italic;
        padding: $global-baseline/2 + px 20px;

        &:hover {
            color: $color-brand
        }
    }

    .active {
        a {
            border-color: $color-brand;
            color: $color-brand;
        }
    }
}

@media screen and (min-width: map-get($grid-breakpoints, medium)) {

    .content-main {
        &.subnav-active {
            margin-top: ($global-baseline * 2) + $global-baseline/2 + px;
        }
    }
}

.blog {

    .sub-nav {
        @media screen and (min-width: map-get($grid-breakpoints, medium)) {
            background: transparent;
        }

    }

    .sub-nav-items {
        li {
            display: block;
            /** for when the RSS rss gets set up
            &:last-child {
                border-top: 1px solid black;
            }
            **/
        }

        .active {
            a {
                border-color: transparent;
            }
        }

        @media screen and (min-width: map-get($grid-breakpoints, medium)) {
            padding-right: 0;
            text-align: right;

            a {
                margin: 0;
                padding-right: 0;
            }
        }

    }
}
