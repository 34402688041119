/*-------------------------------------*
 * Dependencies: $grid-breakpoints, $global-baseline in helpers/vars
 *-------------------------------------*/

/* Debug settings */
$typesetter-horizontal-debug: false;

@function rem($pixels, $context: $global-browser-context) {
    @return #{$pixels/$context}rem;
}

@mixin repeating-linear-gradient($gradients) {
    background: -webkit-repeating-linear-gradient( $gradients );
    background: -moz-repeating-linear-gradient( $gradients );
    background: -o-repeating-linear-gradient( $gradients );
}

@mixin gridlines($deg: -90deg, $line-color: #ccc) {
    $gradients: $deg, transparent, transparent $global-baseline - 1 + px, $line-color $global-baseline + px;

    @if $deg == -90deg {
        @include repeating-linear-gradient( $gradients );
        background-size: 100% $global-baseline + px;
    } @else {
        @include repeating-linear-gradient( $gradients );
        background-size: $global-baseline + px 100%;
        background-position: 50% 100%;
    }
}

@mixin setType($font-size: $global-browser-context, $margin: $global-baseline, $line-height: 1) {
    $base: $global-baseline;

    @if $font-size > $global-baseline {
        @if $font-size % $global-baseline == 0 {
            $base: $font-size;
        } @else {
            $offset: $font-size % $global-baseline;
            $base: ($font-size - $offset) + $global-baseline;
        }
    }

    @if $font-size > 17 and $font-size < 25 {
        $offset: $font-size % 29;
        $base: ($font-size - $offset) + 29;
    }

    font-size: $font-size + px;
    font-size: rem($font-size);
    line-height: ($base/$font-size);

    @if $margin {
        margin: $margin + px 0;
    } @else {
        margin: $global-baseline + px 0;
    }
}

@mixin mediaQuery($min-width) {
    @media screen and (min-width: #{$min-width}) {
        @content;
    }
}

@mixin setTypographicalRhythm($typeSize: $global-browser-context, $min-width: false) {
    @if $min-width {
        @include mediaQuery($min-width) {
            @include setType($typeSize);
        }
    } @else {
        @include setType($typeSize);
    }
}

%setDefaultType {
    @include setType(16);
}

@mixin setRelativeType() {
    // Set default sizes
    @each $selector, $sizes in $typography-map {
        #{$selector}, .#{$selector} {
            @include setType(map-get($sizes, default));
        }
    }

    ul, ol, dl,
    td, th, caption,
    blockquote, pre, var, code,
    legend, input, textarea, select, label {
        @extend %setDefaultType;
    }

    input, textarea, select, label {
        margin: 0;
    }

    // Set responsive sizes
    @each $selector, $sizes in $typography-map {
        #{$selector}, .#{$selector} {
            @include setTypographicalRhythm(map-get($sizes, small), map-get($grid-breakpoints, small));
        }
    }

    @each $selector, $sizes in $typography-map {
        #{$selector}, .#{$selector} {
            @include setTypographicalRhythm(map-get($sizes, medium), map-get($grid-breakpoints, medium));
        }
    }

    @each $selector, $sizes in $typography-map {
        #{$selector}, .#{$selector} {
            @include setTypographicalRhythm(map-get($sizes, large), map-get($grid-breakpoints, large));
        }
    }

    @each $selector, $sizes in $typography-map {
        #{$selector}, .#{$selector} {
            @include setTypographicalRhythm(map-get($sizes, x-large), map-get($grid-breakpoints, x-large));
        }
    }
}

body {
    @if $typesetter-horizontal-debug {
        @include gridlines();
    }
}
