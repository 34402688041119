.client-story-intro {
    background: $color-brand;
    color: $white;
    position: relative;
    transform-style: preserve3d;
    perspective: 100px;
    padding: 8.5rem $global-baseline + px 0 4rem;
    //overflow: hidden;
    z-index:50;

    .max-width-container {
        margin: 0 auto 6rem auto;
    }

    .intro-content {
        padding-bottom: 2rem;

        h2 {
            font-size: 1.25rem;
            margin: 0;
        }

        h1 {
            margin: 0;
        }

        p {
            margin: 0;
            padding-bottom: 1rem;
        }
        // .photo-container {
        //     img {
        //         min-width: 22rem;
        //         margin-left: -203.5px;
        //         left: 50%;
        //     }
        // }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .grid {
                display: grid;
                grid-template-columns: 45% 45%;
                grid-gap: 5%;

            }
            .photo-container {
                img {
                    transform:translateX(-50%);
                    left: 50%;
                }
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
            .grid {
                display: grid;
                grid-template-columns:40% 55%;
                grid-gap: 5%;

            }
            .photo-container {
                min-height: 18rem;
                img {
                    max-height: 25rem;
                    min-width: 22rem;
                    right: 0;
                    margin-left: 0;
                    transform:none;
                    left: auto;
                }
            }
        }
    }

    .angle-bkgd {
        position: absolute;
        background: $color-brand;
        height: 100%;
        transform: rotate(-4deg);
        left: -10%;
        right: -10%;
        bottom: -50px;
    }
    .flair-svg {
        position: absolute;
        top: 0.5rem;
        left: -2rem;
        z-index: 30;
        height: 200%;
    }

    .photo-container {
        min-height: 11rem;
        position: relative;

        img {
            min-width: 21rem;
            max-height: 17rem;
            position: absolute;
            bottom: -105px;
            left: 50%;
            transform:translateX(-50%);
            box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
        }
    }
  }

.client-story-container {
    overflow: hidden;
    position:relative;
    z-index: 60;
    padding: 0 $global-baseline + px $global-baseline + px 4rem;
    margin-top: -31px;

    @include mediaQuery(map-get($grid-breakpoints, medium)) {
        margin-top: -19px;
    }

    @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
        margin-top: -12px;
    }

    @include mediaQuery(map-get($grid-breakpoints, large)) {
        margin-top: 0px;
    }

    .flair-svg-blue {
        position: absolute;
        left: -32px;
        top: 0;
    }

    .client-story-content {
        padding-top: 1.5rem;
    }

    .client-story-coach {
        margin-left: -2rem;

        h6 {
            color: $color-medium-dark-gray;
            font-size: 0.95rem;
            margin: 0;
        }
        h5 {
            font-size: 1.25rem;
            margin: 0 0 0.5rem 0;
        }

        img {
            margin-bottom: 1rem;
            display: block;
            box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
        }
    }
}
