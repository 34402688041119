.client-stories {
    .client-stories-intro {
        position: relative;
        transform-style: preserve3d;
        perspective: 100px;
        padding: 4rem $global-baseline + px 0 $global-baseline + px;
        overflow: hidden;

        .max-width-container {
            padding-bottom: 4rem;
            max-width: 76rem;
        }

        .angle-bkgd {
            position: absolute;
            background: $color-light-gray;
            height: 100%;
            transform: rotate(-4deg);
            left: -10%;
            right: -10%;
            top: -75px;
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .intro-content {
                max-width: 80%;
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, large)) {
            .intro-content {
                max-width: 60%;
            }
        }
    }

    .featured-stories-slider {
        max-width: 75rem;
        margin: 0 auto;

        h5{
            font-weight: 300;
            font-size: 0.85rem;
        }

        .mobile {
            display: block;

            h4 {
                margin: 0 0 1rem 0;
            }

            .featured-story-slide {
                .client-story-intro {
                    background: $white;
                    padding: 0;
                }

                .client-intro {
                    color: $color-medium-gray;
                    font-size: 1rem;
                    margin-bottom: 0;
                }

                .client-name {
                    font-size: 1.25rem;
                    margin-top: 0;
                    color: $black;
                }

                .client-quote {
                    color: $color-medium-dark-gray;
                    font-style: italic;
                    font-size: 1.25rem;
                    margin-bottom: 1rem;
                }

                img {
                    box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
                    margin-bottom: 2rem;
                }
            }
        }

        .desktop {
            display: none;

            &.featured-carousel {
                box-shadow: 0 0px 10px 0 rgba(0,0,0,0.1);
                margin-bottom: 2rem;
            }

            .featured-story-slide {
                background: $white;
                box-shadow: 0 0 12px 0 rgba(211,213, 215, 0.77);
                grid-template-columns: 39% 58%;
                grid-gap: 3%;
                display: grid;

                .client-story-intro {
                    background: $white;
                    padding: 2rem;
                }

                .client-intro {
                    color: $color-medium-gray;
                    font-size: 1rem;
                    margin: 0;
                }

                .client-name {
                    font-size: 1.5rem;
                    margin-top: 0;
                    color: $black;
                }

                .client-quote {
                    color: $color-medium-dark-gray;
                    font-style: italic;
                    font-size: 1.5rem;
                    margin-bottom: 1rem;
                }

                img {
                    float: right;
                }
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            h5{
                margin: 0 0 1rem 1.5rem;
            }

            .mobile {
                display: none;
            }

            .desktop {
                display: block;
            }
        }
    }
}