html {
    font: 100%/1.5 $font-main;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
}

@include setRelativeType();

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
    font-family: $font-heading;
}

a {
    text-decoration: none;
}

blockquote {
    @include setType(33, 0);
    font-family: $font-main;
}

// Prevent the Flash of Unstyled Text (FOUT)
body {
    transition: opacity .2s ease-in-out;
}

/* These classes are generated by Typekit to use before Typekit is loaded */
.wf-loading {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p,
    .nav-main,
    .sub-nav,
    body {
        opacity: 0;
        visibility: hidden;
    }
}

/* styles to use after Typekit is loaded */
.wf-active {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p,
    .nav-main,
    .sub-nav,
    body {
        opacity: 1;
        visibility: visible;
    }
}
