.coaches {
    .coaches-intro {
        position: relative;
        transform-style: preserve3d;
        perspective: 100px;
        padding: 4rem $global-baseline + px 0 $global-baseline + px;
        overflow: hidden;

        .max-width-container {
            padding-bottom: 4rem;
            max-width: 76rem;
        }

        .angle-bkgd {
            position: absolute;
            background: $color-light-gray;
            height: 100%;
            transform: rotate(-4deg);
            left: -10%;
            right: -10%;
            top: -75px;
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .intro-content {
                max-width: 80%;
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, large)) {
            .intro-content {
                max-width: 60%;
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns:repeat(auto-fill, minmax(400px, 1fr));;
        grid-gap: 1rem;
        .col {
            align-self: stretch;
            margin: 0 10%;

            img {
                width: 100%;
            }

            a {
                h3 {
                    color: $black;
                }
            }

            @include mediaQuery(map-get($grid-breakpoints, medium)) {
                h3 {
                    font-size: 1.25rem;
                }
            }

            @include mediaQuery(map-get($grid-breakpoints, large)) {
                h3 {
                    font-size: 1.5rem;
                }
            }

        }
    }
}