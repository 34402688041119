.callout {
    background: url('/assets/images/pattern-green.svg') center repeat;
    background-size: 20%;
    clear: both;
    margin-top: $global-baseline * 3 + px;
    padding: $global-baseline + px 10%;
    width: 100%;
    @include mediaQuery(map-get($grid-breakpoints, medium)) {

        padding: $global-baseline * 4 + px 10%;

    }
}