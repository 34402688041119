input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
    -webkit-appearance: none;
    background: $white;
    border: 2px solid $color-medium-gray;
    border-radius: 3px;
    box-shadow: none;
    box-sizing: border-box;
    display: block;
    height: 57px;
    font-size: 1.25rem;
    padding: 13px 16px 13px;
    margin: 0;
    width: 100%;
}

/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

textarea {
    min-height: $global-baseline * 4 + px;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
    outline: 0;
}

label,
legend {
    display: block;
    font-weight: 300;
}

legend {
    @include setType(20, 0);

    padding: $global-baseline + px 0 0;
}

fieldset {
    padding: 0;
    border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
    display: inline;
}

label > .label-body {
    display: inline-block;
    margin-left: .5rem;
    font-weight: normal;
}

::-webkit-input-placeholder {
   color: $color-brand-pink;
   font-style: italic;
}

.select-container {
    position: relative;

    &:before, &:after {
        position: absolute;
        content: '';
        display: block;
        right: 1rem;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
    }

    &:before {
        border-top: 8px solid $color-medium-gray;
        top: 50%;
        margin-top: -4px;
    }
}


.form-row {
    margin: $global-baseline + px 0;
}

.form-group {
    position: relative;
}

/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */


// errors
.error-inline {
    @include setType(14, 0);

    color: $color-brand-pink;
    font-style: italic;
    position: absolute;
}

.form-group {
    .icon-error,
    .icon-ok {
        position: absolute;
        right: 12px;
        top: 0;
        font-size: 24px;
    }

    .icon-error {
        color: $color-brand-pink;
    }

    .icon-ok {
        color: $color-brand-orange;
    }
}
