.accordion {
    h3 {
        padding: 0 0 1rem 1rem;
        position: relative;
        margin: 0;

        &:hover {
            cursor: pointer;
        }

        a {
            color: $black;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid $color-brand;
        }

        &.active {
            &:before {
                transform: rotate(90deg)
            }
        }
    }
    .content {
        display: none;
        padding: 0 0 1rem 1rem;

        ul {
            padding: 0;
        }
    }

    @include mediaQuery(map-get($grid-breakpoints, medium)) {
        h3 {
            font-size: 1.5rem;

            &:before {

                top: 13px;
            }
        }
    }
}
