.info-cards {
    background-color: $white;
    margin-top: $global-baseline - 1 + px;
    overflow: hidden;

    .card {
        margin-bottom: $global-baseline + px;
        position: relative;
        top: 1px;

        &:last-child {
            border-bottom: 0;
        }

        ul {
            padding: 0;
            text-align: left;

            li {
                @include setType(16, 0);
                margin: 0;
                padding: 0;
            }
        }
    }

    .box {
        background: #fff;
        color: $black;
        display: block;
        margin-bottom: $global-baseline - 1 + px;
        padding: $global-baseline + px;
        text-align: center;

        .heading {
            @include setType(28);
            text-align: center;
            margin-top: 0;
        }

        &:hover {
            background: #fff;
        }

    }

    .benefit-icon {
            width: 50%;
        }

    @include mediaQuery(map-get($grid-breakpoints, medium)) {
        margin-right: -$global-baseline + px;
        margin-left: -$global-baseline + px;
        text-align: center;

        .card {
            border-right: 1px solid $color-light-gray;
            display: inline-block;
            margin-bottom: 0;
            vertical-align: top;
            width: 50%;

            &:nth-child(2n+2),
            &:last-child {
                border-right: 0;
            }

            &:last-child {
                border-bottom: 1px solid $color-light-gray;
            }
        }

        .box {
            margin: $global-baseline + px $global-baseline + px $global-baseline - 1 + px;
            min-height: 18rem;
        }

    }

    @include mediaQuery(map-get($grid-breakpoints, large)) {
        .card {
            width: 33.3333%;

            &:nth-child(2n+2) {
                border-right: 1px solid $color-light-gray;
            }

            &:nth-child(3n+3) {
                border-right: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }
}
