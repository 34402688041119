.share {
    list-style: none;
    margin: 0;
    padding: 0;

    .item {
        display: inline-block;
        margin-right: 1rem;
    }

    .anchor {
        background-color: $color-brand-pink;
        border-radius: 50%;
        color: $white;
        display: block;
        height: $global-baseline + px;
        width: $global-baseline + px;
        text-align: center;

        &:hover,
        &:focus {
            background-color: darken($color-brand-pink, 10%);
        }
    }

    .icon {
        font-size: 12px;
        display: inline-block;
        line-height: $global-baseline + px;
        vertical-align: top;
    }
}

.share-inline {
    display: inline-block;
    vertical-align: middle;

    .share-phrase {
        color: $white;
        font-style: italic;
    }

    .share {
        display: inline-block;
        padding-left: 0.5rem;
        vertical-align: middle;
    }
}
