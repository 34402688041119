// .animate-dot-center {
//     animation-name: dotappear;
//     animation-duration: 0.4s;
//     animation-timing-function: ease;
//     animation-fill-mode: both;
// }

// .animate-dot-left {
//     animation-name: dotappear;
//     animation-duration: 0.4s;
//     animation-timing-function: ease;
//     animation-delay: 0.4s;
//     animation-fill-mode: both;
// }

// .animate-dot-right {
//     animation-name: dotappear;
//     animation-duration: 0.4s;
//     animation-timing-function: ease;
//     animation-delay: 0.8s;
//     animation-fill-mode: both;
// }

// .animate-line {
//     &.left {
//         &:after {
//             right: 0;
//         }

//     }

//     &.right {
//         &:after {
//             left: 0;
//         }

//     }

//     &:after {
//         content: '';
//         position: absolute;
//         bottom: 0;
//         height: 2px;
//         background: transparent;
//         animation-name: lineappear;
//         animation-duration: 2s;
//         animation-timing-function: ease;
//         animation-fill-mode: both;
//         animation-delay: 1s;
//     }

// }

// .animate-line-orange {
//     &.left {
//         &:after {
//             right: 0;
//         }

//     }

//     &.right {
//         &:after {
//             left: 0;
//         }

//     }

//     &:after {
//         content: '';
//         position: absolute;
//         bottom: 0;
//         height: 2px;
//         background: transparent;
//         animation-name: lineappearorange;
//         animation-duration: 2s;
//         animation-timing-function: ease;
//         animation-fill-mode: both;
//         animation-delay: 1s;
//     }

// }

// @keyframes dotappear {
//     0% {
//         opacity: 0;
//     }

//     50% {
//         opacity: .5;
//     }

//     100% {
//         opacity: 1;
//     }
// }

// @keyframes lineappear {
//     0% {
//         width: 0;
//         background: $color-accent;
//     }

//     100% {
//         width: 100%;
//         background: $color-accent;
//     }
// }

// @keyframes lineappearorange {
//     0% {
//         width: 0;
//         background: $color-brand;
//     }

//     100% {
//         width: 100%;
//         background: $color-brand;
//     }
// }

.is-loading {
    display: none;
}

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1rem;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid $color-brand ;
    border-color: $color-brand transparent $color-brand  transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.spinner {
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 4rem;
}