.content, .container {
    blockquote{
        @include setType(24, 0);

        font-style: italic;
        padding-top: $global-baseline + px;
        padding-bottom: $global-baseline + px;
        max-width: 95%;
        margin: 0 auto;
        color: $color-medium-dark-gray;
        @media screen and (min-width: map-get($grid-breakpoints, medium)) {
            max-width: 80%;
        }

    }

    ul, ol {
        padding: 0 $global-baseline + px;
        li {
            &:last-child {
                margin-bottom: 0;
            }
            @media screen and (min-width: map-get($grid-breakpoints, small)) {
                @include setType(16, 0);

            }

            @media screen and (min-width: map-get($grid-breakpoints, medium)) {
                @include setType(18, 0);

            }
            @media screen and (min-width: map-get($grid-breakpoints, large)) {
                @include setType(20, 0);

            }
        }

    }

    hr {
        margin-top: $global-baseline * 2 + px;
        margin-bottom: $global-baseline + px;
    }
    &.one {
        hr {
            margin-top: $global-baseline * 2 + px;
            margin-bottom: $global-baseline + px;
        }
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
