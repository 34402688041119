@each $key, $value in $grid-breakpoints {
    @include mediaQuery($value) {
        body {
            &:after {
                content: "#{$key}";
                display: none;
            }
        }
    }
}
