.page-navigation {
    ul {
        @extend %scrub-list-style;

        background: $white;
        display: flex;
        margin: 0;

        li {
            align-items: center;
            display: block;
            margin: 0;
            padding: 0;
            text-align: center;
            vertical-align: top;
            width: 100%;
            a {
                border-top: 1px solid $color-light-gray;
                color: $black;
                display: block;
                font-family: $font-heading;
                padding: $global-baseline + px;
                position: relative;
                width: 100%;
                &:hover {
                    background: $color-light-gray;
                    color: $color-brand;

                }
            }

            &:hover {
                background: $color-light-gray;
                color: $color-brand;

            }
        }

        @media screen and (min-width: 64em) {
            border-top: 1px solid $color-light-gray;
            li {
                @include setType(30);

                border-top: 0;
                display: inline-block;
                margin: 0;
                width: 50%;

                &:first-child {
                    text-align: left;
                    border-right: 1px solid $color-light-gray;

                    a {

                        &:before {
                            background: url('/assets/images/grey-left.svg') left center no-repeat;
                            content: " ";
                            display: block;
                            height: 6px;
                            width: 22px;
                            margin-top: -2px;
                            position: absolute;
                            top: 50%;
                            left: 26px;
                        }

                        &:hover {
                            &:before {
                                background: url('/assets/images/orange-left.svg') left center no-repeat;
                            }
                        }
                    }
                }

                &:last-child {
                    text-align: right;
                    a {
                        &:after {
                            background: url('/assets/images/grey-right.svg') left center no-repeat;
                            content: " ";
                            display: block;
                            height: 6px;
                            width: 22px;
                            margin-top: -2px;
                            position: absolute;
                            top: 50%;
                            right: 26px;
                        }
                        &:hover {
                            &:after {
                                background: url('/assets/images/orange-right.svg') left center no-repeat;
                            }
                        }
                    }
                }

                a {
                    border-top: 0;
                    padding-left: $global-baseline * 3 + px;
                    padding-right: $global-baseline * 3 + px;
                }
            }


        }

    }
}