.mailing-list {
    position: relative;
    text-align: center;

    .hbspt-form {
        .hs-form-required {
            right: 20px;
            position: absolute;
            top: 37px;
        }
    }

    .mailing-list-form-container {
        background-image: url('/assets/images/mailing-list.svg');
        background-repeat: no-repeat;
        background-attachment: local;
        background-position: 0 50%;
        max-width: 640px;
        margin: 0 auto;
        padding: $global-baseline * 2 + px;
    }

    .mailing-list-title {
        text-align: center;
        margin-top: 0;
    }

    label {
        text-align: left;
    }

    .hs-button {
        @extend %boxshadow;
        background: $color-brand;
        border: 3px solid $color-brand;
        border-radius: 4px;
        color: $white;
        display: block;
        font-family: $font-heading;
        margin: 0 auto;
        padding: $global-baseline * .2 + px;
        text-align: center;

        &:hover {
            box-shadow: none;
        }
    }

    .hbspt-form {
        max-width: 420px;
        margin: 0 auto;
    }

    .btn-default {
        &:after {
            background: $color-light-gray;
        }
    }
}
