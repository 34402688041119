body, html {
    height: 100%;
}
.grey-container {
    background: $color-light-gray;
}

.home, .nutrition-plans {
    .global-main-wrapper {
        .main-container {
            margin-top: -5.25rem;
        }
    }
}

.global-main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    header {
        align-self: flex-start;
    }
    .main-container {
        width: 100%;
        min-height: 65%;

    }

    footer {
        align-self: flex-end;
    }
}
