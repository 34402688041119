/*
 * jQuery FlexSlider v2.7.2
 * https://www.woocommerce.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
 @font-face {
    font-family: 'flexslider-icon';
    src: url('/assets/fonts/flexslider-icon.eot');
    src: url('/assets/fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/flexslider-icon.woff') format('woff'), url('/assets/fonts/flexslider-icon.ttf') format('truetype'), url('/assets/fonts/flexslider-icon.svg#flexslider-icon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  /* ====================================================================================================================
   * RESETS
   * ====================================================================================================================*/
  .flex-container a:hover,
  .flex-slider a:hover {
    outline: none;
  }
  .slides,
  .slides > li,
  .flex-control-nav,
  .flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .flex-pauseplay span {
    text-transform: capitalize;
  }
  /* ====================================================================================================================
   * BASE STYLES
   * ====================================================================================================================*/
  .flexslider {
    margin: 0;
    padding: 0;
  }

  .flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
  }

  // #carousel.flexslider .slides {
  //   width: 96px;
  // }

  // .flexslider .slides .thumbnail {
  //   position: relative;
  //   width: 96px;
  //   height: 96px;
  //   //overflow: hidden;
  // }

  // .flexslider .slides .thumbnail img {
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   height: 100%;
  //   width: auto;
  //   -webkit-transform: translate(-50%,-50%);
  //       -ms-transform: translate(-50%,-50%);
  //           transform: translate(-50%,-50%);
  // }

  // .flexslider .slides .thumbnail img.portrait {
  //   width: 100%;
  //   height: auto;
  // }


  .flexslider .slides img {
    width: 100%;
    display: block;
  }
  .flexslider .slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  html[xmlns] .flexslider .slides {
    display: block;
  }
  * html .flexslider .slides {
    height: 1%;
  }
  .no-js .flexslider .slides > li:first-child {
    display: block;
  }
  /* ====================================================================================================================
   * DEFAULT THEME
   * ====================================================================================================================*/
  .flexslider {
    margin: 0 0 60px;
    //background: #fff;
    //border: 4px solid #fff;
    position: relative;
    zoom: 1;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
    -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
    box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  }
  .flexslider .slides {
    zoom: 1;
  }
  .flexslider .slides img {
    height: auto;
    -moz-user-select: none;
  }
  .flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }
  .loading .flex-viewport {
    max-height: 300px;
  }
  @-moz-document url-prefix() {
    .loading .flex-viewport {
      max-height: none;
    }
  }
  .carousel li {
    margin-right: 5px;
  }
  .flex-direction-nav {
    *height: 0;
  }
  .flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    //overflow: hidden;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .orange {
    .flex-direction-nav a.flex-prev:before {
      border-right:20px solid $color-brand-orange;
    }

    .flex-direction-nav a.flex-next:before {
      border-left: 20px solid $color-brand-orange;
    }
  }

  .flex-direction-nav a {
    color: transparent;
    text-shadow: none;
  }
  .flex-direction-nav a:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  .flex-direction-nav a.flex-prev:before {
    border-right:20px solid $black;
    left: 0;
  }

  .flex-direction-nav a.flex-next:before {
    border-left: 20px solid $black;
    right: 0;
  }
  .flex-direction-nav .flex-prev {
    left: -40px;
    opacity: 1;
  }
  .flex-direction-nav .flex-next {
    right: -40px;
    text-align: right;
    opacity: 1;
  }
  .flexslider:hover .flex-direction-nav .flex-prev {
    opacity: 0.7;
    left: -40px;
  }
  .flexslider:hover .flex-direction-nav .flex-prev:hover {
    opacity: 1;
  }
  .flexslider:hover .flex-direction-nav .flex-next {
    opacity: 0.7;
    right: -40px;
  }
  .flexslider:hover .flex-direction-nav .flex-next:hover {
    opacity: 1;
  }
  .flex-direction-nav .flex-disabled {
    opacity: 0!important;
    filter: alpha(opacity=0);
    cursor: default;
    z-index: -1;
  }
  .flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000;
  }
  .flex-pauseplay a:before {
    font-family: "flexslider-icon";
    font-size: 20px;
    display: inline-block;
    content: '\f004';
  }
  .flex-pauseplay a:hover {
    opacity: 1;
  }
  .flex-pauseplay a.flex-play:before {
    content: '\f003';
  }
  .flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
  }
  .flex-control-nav li {
    margin: 0 14px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    position: relative;
  }

  &.orange {
    .flex-control-paging li a {
      background: $color-brand-orange;
    }

    .flex-control-paging li a:hover {
      background: $color-brand-orange;
    }

    .flex-control-paging li a.flex-active {
      background: $color-brand-orange;
    }
  }

  .flex-control-paging li a {
    width: 7px;
    height: 7px;
    display: block;
    background: $color-brand;
    cursor: pointer;
    text-indent: -9999px;
  }
  .flex-control-paging li a:hover {
    background: $color-brand;
  }
  .flex-control-paging li a.flex-active {
    height: 14px;
    width: 14px;
    background: $color-brand;
    position: absolute;
    top: -21px;
    left: -5px;
    cursor: default;
  }
  .flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
  }
  .flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
  }
  .flex-control-thumbs img {
    width: 100%;
    height: auto;
    display: block;
    opacity: .7;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }
  .flex-control-thumbs img:hover {
    opacity: 1;
  }
  .flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default;
  }

  .carousel {
    li {
      &:hover {
        cursor: pointer;
      }
    }
    img {
      margin-bottom: 10px;
      box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
    }

    .flex-active-slide {
      border-bottom: 6px solid $color-brand;
    }
  }
  /* ====================================================================================================================
   * RESPONSIVE
   * ====================================================================================================================*/
  @media screen and (max-width: 860px) {
    .flex-direction-nav .flex-prev {
      opacity: 1;
      left: -40px;
    }
    .flex-direction-nav .flex-next {
      opacity: 1;
      right: -40px;
    }
  }
