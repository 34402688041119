.blog {

    .max-width-wrapper {
        margin: 0 auto;
        max-width: 66rem;
        position: relative;
    }

    .blog-search-wrapper {
        position: relative;

        &.wrapper {
            padding: 0 24px 24px 24px;

        }

        .icon-search {
            color: $color-medium-dark-gray;
            position: absolute;
            top: 14px;
            right: 2.5rem;
            font-size: 1.25rem;
        }

        .blog-search {
            background: transparent;
            margin-top: -1rem;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid $color-light-gray;

            &::placeholder {
                color: $color-medium-dark-gray;
                font-size: 1.25rem;
                font-style: normal;
            }
        }
    }

    .blog-intro {
        position: relative;
        transform-style: preserve3d;
        perspective: 100px;
        padding: 4rem $global-baseline + px 0 $global-baseline + px;
        overflow: hidden;

        .angle-bkgd {
            position: absolute;
            background: $color-light-gray;
            height: 100%;
            transform: rotate(-4deg);
            left: -10%;
            right: -10%;
            top: -75px;
        }
        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .intro-content {

                .half {
                    width: 50%;
                    float: left;
                };

            }
        }

        @include mediaQuery(map-get($grid-breakpoints, med-large)) {
            .intro-content {
               padding: 24px;

            }
        }
    }

    .blog-featured {

        .block {
            padding: 0;

            &.blog-callout {
                h4 {
                    font-weight: 300;
                    font-size: 1rem;
                    margin: 0 0 1rem 0;
                }

                h5, h1 {
                    margin: 0 0 1rem 0;
                }

                p {
                    margin: 0 0 1rem 0;
                    display: block;
                }

                .read-more {
                    display: block;
                    font-size: 0.85rem;
                    font-weight: 500;
                    color: $color-medium-dark-gray;
                    margin: 0 0 1rem 0;
                }

                .blog-featured-image {
                    box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
                }

                @include mediaQuery(map-get($grid-breakpoints, medium)) {
                    h5, h1 {
                        font-size: 3rem;
                        line-height: 3.5rem
                    }

                    .tag {
                        display: block;
                        margin: 0 0 1rem 0;
                    }
                }

            }
        }
    }

    .blog-topics {
        .mobile {
            display: block;
        }
        .desktop {
            display: none;
        }

        .tab-container {
            text-align: center;
            border-bottom: 1px solid $color-light-gray;
            position: relative;
            line-height: 11px;

            ul {
                display: inline-block;
                list-style: none;
                margin: 0 auto;

                &.health {
                    a {
                        &.active {
                            border-bottom: 4px solid $color-brand-pink;
                        }
                    }
                }

                &.tips {
                    a {
                        &.active {
                            border-bottom: 4px solid $color-brand-yellow;
                        }
                    }
                }

                &.performance {
                    a {
                        &.active {
                            border-bottom: 4px solid $color-brand-orange;
                        }
                    }
                }

                &.lifestyle {
                    a {
                        &.active {
                            border-bottom: 4px solid $color-brand-purple;
                        }
                    }
                }

                &.education {
                    a {
                        &.active {
                            border-bottom: 4px solid $color-brand;
                        }
                    }
                }

                &.recipes {
                    a {
                        &.active {
                            border-bottom: 4px solid $color-brand-green;
                        }
                    }
                }

                li {
                    float: left;

                    a {
                        color: $color-medium-dark-gray;
                        font-size: 1.25rem;
                        padding: 1rem;
                        display: block;
                        border-bottom: 4px solid transparent;

                        &.active {
                            border-bottom: 4px solid $color-brand;
                        }

                        .icon {
                            margin: 0 1rem 0 0;

                            &.icon-tips {
                                color: $color-brand-yellow;
                            }

                            &.icon-health {
                                color: $color-brand-pink;
                            }

                            &.icon-lifestyle {
                                color: $color-brand-purple;
                            }

                            &.icon-performance {
                                color: $color-brand-orange;
                            }

                            &.icon-recipes {
                                color: $color-brand-green;
                            }

                            &.icon-education {
                                color: $color-brand;
                            }
                        }

                        &:hover {
                            border-bottom: 4px solid $color-brand;
                        }
                    }
                }
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .mobile {
                display: none;
            }
            .desktop {
                display: block;
            }
        }
    }

    .blog-listing {
        .entry-with-image {
            &.grid {
                grid-template-columns: 36% 56%;
                grid-gap: 4rem;
                margin-bottom: 2rem;
            }
        }

        .entry-no-image {
            &.grid {
                grid-template-columns: 70%;
                grid-gap: 4rem;
                justify-content: center;
                margin-bottom: 2rem;


            }
        }

        .blog-image {
            text-align: center;
        }

        .read-more {
            display: block;
            font-size: 0.85rem;
            font-weight: 500;
            color: $color-medium-dark-gray;
            margin: 0 0 1rem 0;
        }

        .blog-intro-image {
            margin-bottom: 3rem;
            box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
            width: 100%;
            max-width: 20rem;

        }
        .tag {
            display: block;
            font-size: 0.95rem;

            .icon {
                padding: 0 0.5rem 0 0;
            }
        }

        a {
            h5 {
                color: $black;
            }
        }

        h4 {
            font-weight: 300;
            font-size: 1rem;
            margin: 0 0 1rem 0;
        }

        h5 {
            margin: 0 0 1rem 0;
        }

        p {
            margin: 0 0 1rem 0;
            display: block;
        }

        .more-blog-posts {
            text-align: center;
            margin-bottom: 2rem;
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            h5 {
                font-size: 3rem;
                line-height: 3.5rem;
            }
        }
    }

    .entry-intro-content {
        margin-top: 4rem;

        &.grid {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 1rem;

            .blog-author {
                order: 2;
            }

            .blog-title {
                order: 1;
            }
        }

        .blog-author {
            margin: 1rem 0 0 0;
            .avatar {
                float: left;
                margin: 0 1rem 0 0;
            }

            h6 {
                margin: 0.25rem 0 0 0;
                text-transform: capitalize;
                font-size: 1rem;
                font-family: $font-main;
            }

            .tag {
                font-size: 0.85rem;

                .icon {
                    padding-right: 0.25rem;
                }
            }
        }

        .blog-title {

            h5 {
                color: $black;
                font-size: 1.5rem;
                line-height: 1.75rem;
                margin: 0 0 1rem 0;
            }

            p {
                margin: 0;
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            padding: 24px 24px 0 24px;

            .blog-title {
                h5, h1 {
                    color: $black;
                    font-size: 2.5rem;
                    line-height: 2.75rem;
                    margin: 0 0 1rem 0;
                }
            }

            &.grid {
                grid-template-columns: 20% 75%;
                grid-gap: 5%;

                .blog-author {
                    order: 1;
                }

                .blog-title {
                    order: 2;
                }
            }
        }
    }

    .entry-content {
        .blog-featured-image {
            box-shadow: 0 7px 12px 0 rgba(211, 213, 215, 0.77);
        }

        table {
            width: 100%;
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .content {
                max-width: 50rem;
                margin: 2rem auto 0 auto;
            }
        }
    }

    .blog-pagination {
        .wrapper {
            &.gray {
                margin: 0;
            }
        }

        .grid {
            display: grid;
            grid-template-columns: 45% 0 45%;
            grid-gap: 5%;

            @include mediaQuery(map-get($grid-breakpoints, medium)) {
                grid-template-columns: 30% 30% 30%;
            }

        }
        a {
            h6 {
                margin: 0.5rem 0;
                font-size: 1rem;
                line-height: 1.25rem;
                color: $black;

                @include mediaQuery(map-get($grid-breakpoints, medium)) {
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                }
            }
        }

        .icon-arrow {
            font-size: 2rem;
            color: $color-brand;
        }

        .previous {
            .tag {
                .icon {
                    padding-right: 0.25rem;
                }
            }

            .icon-arrow {
                position: relative;
                display: inline-block;
                transform: rotate(180deg);
            }
        }

        .next {
            text-align: right;
            .tag {
                .icon {
                    padding-left: 0.25rem;
                }
            }
        }
    }

    &.category {
        .intro-content {
            h4 {
                font-size: 1rem;
                margin: 0;
            }

            h1 {
                position: relative;
                margin-top: 0;
                margin-left: 2.5rem;

                .icon {
                    position: absolute;
                    left: -2.5rem;
                    top: 5px;
                    font-size: 2rem;

                    &.icon-tips {
                        color: $color-brand-yellow;
                    }

                    &.icon-health {
                        color: $color-brand-pink;
                    }

                    &.icon-lifestyle {
                        color: $color-brand-purple;
                    }

                    &.icon-performance {
                        color: $color-brand-orange;
                    }

                    &.icon-education {
                        color: $color-brand;
                    }

                    &.icon-recipes {
                        color: $color-brand-green;
                    }
                }
            }
        }

        .more-blog-posts {
            .btn-accent {
                &.tips {
                    background: $color-brand-yellow;
                }

                &.health {
                    background: $color-brand-pink;
                }

                &.performance {
                    background: $color-brand-orange;
                }

                &.lifestyle {
                    background: $color-brand-purple;
                }

                &.education {
                    background: $color-brand;
                }

                &.recipes {
                    background: $color-brand-green;
                }
            }
        }
    }

}

